@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/fontMixins.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  .icon {
    font-size: 4em;
  }

  .title {
    @include proxima-nova-bold;
    margin-top: 20px;
    margin-bottom: 0;
    text-align: center;
  }
  .subtitle {
    margin-top: 30px;
    margin-bottom: 0;
    font-size: 0.875em;
    text-align: center;
    @include proxima-nova-thin;
  }
  .button {
    width: 100%;
    height: 54px;
    border-radius: 100px;
    @include proxima-nova-bold;
    margin-top: 20px;
    margin-bottom: 10px;
    background-color: $baltic-sea;
    color: $white;
    border-color: $baltic-sea;
  }
}
