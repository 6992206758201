@import './mediaQueries.scss';
// Small devices
@mixin sm {
  @media (max-width: #{$screen-mobile}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$screen-tablet}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-mobile}) and (max-width: #{$screen-desktop}) {
    @content;
  }
}

// Small desktop screen
@mixin sds {
  @media (min-width: #{$screen-desktop}) and (max-width: #{$screen-large-desktop}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-desktop}) {
    @content;
  }
}

// Large devices
@mixin xlg {
  @media (min-width: #{$screen-large-desktop}) {
    @content;
  }
}

// Custom screensize
@mixin rwd($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}
