@import '../../assets/styles/colors';
@import '../../assets/styles/mediaQueriesMixings.scss';

.loggedNavContainer, .regularNavContainer {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  justify-content: space-between;
}

.regularNavContainer {
  .actionContainer {
    display: flex;

    &.isOpen {
      flex-direction: column;

      a {
        padding: 0;
        margin-top: 25px;
        .button {
          width: 100%;
          margin-right: 0 !important;
          height: 40px;
          background-color: $light-indigo;
          border: 1px solid $light-indigo;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;

          &.hasTransparentBackground {
            background-color: transparent;

            &:hover {
              background-color: $light-indigo;
            }
          }

          &:hover {
            background-color: $iris;
            border-color: $iris;
          }
        }
      }

      a:nth-child(1) {
        margin-top: 0;
      }
    }
  }
}

.navItem {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navItem {
  color: $baltic-sea !important;

  .icon {
    color: inherit;
  }

  .notifWrapper {
    position: relative;
    align-items: center;
    position: relative;
    display: flex;

    .notifActiveWrapper {
      position: absolute;
      top: -8px;
    }

    .notifActive {
      width: 9px;
      height: 9px;
    }

    .notifActiveWrapperRight {
      width: 100%;
      right: -18px;
    }
  }

  .settings {
    background-color: $white;
    padding: 2px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    .settingsIcon {
      width: 16px;
      height: 16px;
      color: $baltic-sea;
      transition: transform 0.4s ease-in-out;
    }
  }

  .settingsText {
    color: $light-indigo;
  }

  &:hover {
    color: $baltic-sea;
  }

  &:hover .settings .settingsIcon {
    transform: rotate(90deg);
  }

  &:hover .bellshake {
    animation: bellshake 0.6s cubic-bezier(.36,.07,.19,.97) both;
    backface-visibility: hidden;
    transform-origin: center;
  }

  &:hover .cartanim {
    animation: cartscale 0.6s cubic-bezier(.36,.07,.19,.97) both;
    backface-visibility: hidden;
    transform-origin: center;
  }
}

.button, .navItem {
  font-size: 13px;
}

.button {
  background-color: $light-indigo;
  border: 1px solid $light-indigo;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  .buttonIcon {
    width: 24px;
    height: 24px;
  }

  .buttonText {
    margin: 0;
    flex-shrink: 0;
  }

  .buttonLoader {
    width: auto;
    & > span {
      width: 14px !important;
      height: 14px !important;
    }
  }

  &.hasVioletColor {
    color: $light-indigo;
    &:hover {
      color: $white;
    }
  }

  &.hasTransparentBackground {
    background-color: transparent;

    &:hover {
      background-color: $light-indigo;
    }
  }

  &:hover {
    background-color: $iris;
    border-color: $iris;
  }

  &.alternativeWallet {
    background-color: $black;
    border-color: $black;
  }

  &.alternativeWallet:hover {
    background-color: $black-medium;
    border-color: $black-medium;
  }
}

.vertical {
  width: 1px;
  height: 20px;
  background-color: #4D4D4D;
}

.horizontal {
  width: 100%;
  background-color: $mine-shaft;
  margin-top: 35px;
  margin-bottom: 0;
}

.loggedActionButtons {
  display: flex;
  flex-direction: row;

  &.isOpen {
    flex-direction: column;
     a {
      padding: 0;
      margin-top: 35px;
      .button {
        width: 100%;
        margin-right: 0 !important;
        height: 40px;
        background-color: $light-indigo;
        border: none;
        border-radius: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .buttonText {
          margin: 0;
          flex-shrink: 0;
        }

        .buttonLoader {
          width: auto;
          & > span {
            width: 14px !important;
            height: 14px !important;
          }
        }

        &:hover {
          background-color: $iris;
          border-color: $iris;
        }
      }
    }
  }
}

.loader {
  width: auto;
  margin-left: auto;
}

@keyframes bellshake {
  0% { transform: rotate(0); }
  15% { transform: rotate(5deg); }
  30% { transform: rotate(-5deg); }
  45% { transform: rotate(4deg); }
  60% { transform: rotate(-4deg); }
  75% { transform: rotate(2deg); }
  85% { transform: rotate(-2deg); }
  92% { transform: rotate(1deg); }
  100% { transform: rotate(0); }
}

@keyframes cartscale {
  0% { transform: scale(1.0); }
  100% { transform: scale(1.08); }
}

@include lg {
  .navItem {
    .settings {
      background-color: $baltic-sea;

      .settingsIcon {
        color: $white;
      }
    }
    .settingsText {
      color: $baltic-sea;
    }
  }
}
