@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mediaQueriesMixings.scss';

.sideWrapper {
  background-color: $light-indigo;
  min-height: 50px;
  justify-content: flex-start;
  align-items: center;

  &.isOnSide {
    background-color: transparent;
    flex-direction: column;
    align-items: flex-start;
  }

  .sideItem {
    font-size: 14px;

    &.isOnSide:nth-child(1) {
      a {
        margin-top: 0;
      }
    }

    .sideLink {
      color: $light-indigo;
      position: relative;
      overflow: hidden;
      transform: translateY(0) scale(1, 1);
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: translateY(-2px) scale(1.04, 1.04) ;
      }
    }
  }
}

@include lg {
  .sideWrapper {
    padding-left: 3.75rem;

    .sideItem {
      .sideLink {
        color: $white;

        &:first-of-type {
          padding-left: 0;
        }
      }
    }
  }
}
