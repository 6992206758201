@import './fonts';
@import './fontMixins.scss';
@import './forms';
@import './icons';

$theme-colors: (
  'primary': $light-indigo,
);

@import '~bootstrap/scss/bootstrap.scss';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: all 5000s ease-in-out 0s;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

body {
  font-family: 'Proxima Nova Regular' !important;
  :global {
    div.container {
      width: 100%;
      min-height: 100%;
      overflow: hidden;
    }
    * {
      letter-spacing: 0.01em;
    }
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
  }
}

strong {
  @include proxima-nova-bold;
}

.active {
  @include proxima-nova-bold;
}

.boldText {
  @include proxima-nova-bold;
}

.throughText {
  text-decoration: line-through;
}

.noMargin {
  margin: 0 !important;
}

// custom modal styles

.modal-header {
  color: inherit;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
  justify-content: center !important;
  position: relative;
  font-size: 14px;
  min-height: 24px;

  .close {
    color: inherit;
    opacity: 1;
    text-shadow: none;
    padding: 0 !important;
    margin: 0 !important;
    position: absolute;
    right: 0;
    font-weight: 400;

    & span[aria-hidden=true] {
      transition: transform 0.3s ease-in-out;
      display: block;
      text-align: center;
      display: flex;
      align-items: baseline;
      justify-content: center;
      font-size: 28px;
      line-height: 20px;
      transform: scale(1.3, 1.3);
      font-family: 'Proxima Nova Thin'
    }

    &:hover span[aria-hidden=true] {
      color: white;
      transform: rotate(90deg) scale(1.3, 1.3);
    }
  }
}

.modal-body {
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tooltip.show {
  opacity: 1 !important;
}

.Dropdown-root {
  &.is-open {
    .Dropdown-control {
      z-index: 1001;
    }
  }
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
}

input[type=radio],
input[type=checkbox] {
  accent-color: $light-indigo;
}
