@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/fontMixins.scss';

.icon {
  margin-top: 30px;
  width: 77px !important;
  height: 77px !important;
}

.title {
  margin-top: 20px;
  margin-bottom: 0;
  text-align: center;
  @include proxima-nova-semibold;
}

.subtitle {
  margin: 30px 30px 0;
  font-size: 0.9375em;
  text-align: center;
  @include proxima-nova-thin;
}

.actionButton {
  border: $baltic-sea;
  background-color: $baltic-sea;
  height: 54px;
  width: 100%;
  margin-top: 30px;
  border-radius: 100px;
  color: $white;
  @include proxima-nova-semibold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .buttonText {
    margin: 0;
    flex-shrink: 0;
  }

  &:hover:enabled {
    border: $dark-jungle-green;
    background-color: $dark-jungle-green;
  }

  &:disabled, &[disabled] {
    border: $dark;
    background-color: $dark;
  }
}

.cancelButton {
  margin-top: 30px;
  margin-bottom: 20px;
}

.spinner {
  width: auto;
  & > span {
    color: inherit;
    width: 16px !important;
    height: 16px !important;
  }
}
