@import '../../assets/styles/colors.scss';
@import '../../assets/styles/fontMixins.scss';
@import '../../assets/styles/mediaQueriesMixings.scss';

.inputIcon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  width: 20px !important;
  height: 20px;

  &.blackLight, &.blackWhite, &.blackGray {
    color: $baltic-sea;
  }

  &.hasAutoSizes {
    width: auto;
    height: auto;
  }

  &.hasDefaultCursor {
    cursor: default;
  }

  &.iw {
    color: $light-indigo;
  }

  &.lightBlack, .whiteBlack {
    color: $white;
  }
}

.doubleIcon {
  position: absolute;
  right: 60px;
}

.bigDoubleIcon {
  position: absolute;
  right: 115px;
}

.middleDoubleIcon {
  position: absolute;
  right: 60px;
}
