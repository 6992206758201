@import '../../assets/styles/colors.scss';

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  > span {
    color: $light-indigo;
    font-size: 1.5rem;
  }

  &.hasAutoWidth {
    > span {
      width: auto;
    }
  }

  &.hasInheritFontSize {
    > span {
      font-size: inherit;
    }
  }

  &.hasSmallSize {
    > span {
      width: 20px !important;
      height: 20px !important;
    }
  }

  &.isWhite {
    > span {
      color: $white;
    }
  }
}
