@import '../../../assets/styles/fontMixins.scss';
@import '../../../assets/styles/colors.scss';

.approveContainer {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    font-size: 3.5em;
  }

  .title {
    margin-top: 20px;
    @include proxima-nova-bold;
  }

  .subtitle {
    font-size: 0.875em;
    margin-bottom: 6px;
    @include proxima-nova-thin;
  }

  .message {
    color: $supernova;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
    max-width: 90%;
    @include proxima-nova-semibold;
  }
  
  .devicesContainer {
    width: 100%;
    .infoContainer {
        margin-top: 20px;
        background-color: $content-background;
        padding: 20px;
        width: 100%;
        border-radius: 14px;

        .infoElement {
          color: $white;
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          align-content: center;

          .title {
            font-size: 0.75em;
            margin-right: 20px;
            margin-top: 0;
            margin-bottom: 0;
            line-height: 1;
            @include proxima-nova-thin;
          }

          .value {
            font-size: 0.75em;
            margin-top: 0;
            margin-bottom: 0;
            line-height: 1;
          }

          &:first-of-type {
            margin-top: 0;
          }
        }

        .denied, .deniedValue {
          font-size: 0.75em;
          margin-bottom: 0;
        }

        .denied {
          margin-top: 15px;
          @include proxima-nova-thin;
        }

        .deniedValue {
          margin-top: 3px;
        }

        .actionButton {
          margin-top: 20px;
          width: 100%;
        }
        .voucher {
          font-size: 10px;
          word-wrap: break-word;
        }
      }
  }

   .cancelButton {
    margin-top: 25px;
    margin-bottom: 20px;
    width: 100%;
  }
}
