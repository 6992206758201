.icon {
  font-size: 1.3em;
  margin-bottom: 1.875rem;
}

.mainText {
  display: flex;
  flex-flow: row wrap;
  margin-left: 10px;
}
