@import './colors';
@import './fontMixins';

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  height: fit-content;
  position: relative;

  button {
    width: 100%;
    height: 54px;
    font-weight: bold !important;
    @include proxima-nova-bold;
  }

  button.autosize {
    width: auto;
    height: auto;
  }

  & button, a, p, h1, h2, h3, h4, h5 {
    margin-bottom: 30px;
  }

  & a {
    color: inherit;
    font-weight: bold !important;
    @include proxima-nova-bold;
  }

  & p {
    @include proxima-nova-thin;
  }

  .regular-text {
    font-size: 15px;
  }

  input {
    border: none;
    outline: none;
    padding: 0;
    font-size: 15px;
    @include proxima-nova-regular;
  }
}

.form-input {
  align-self: stretch;
  margin-bottom: 0;
  line-height: 1;

  input {
    border-color: $mercury;
    height: 58px;
    padding-left: 22px;

    @media only screen and (max-width: 1280px) {
      height: 30px;
      padding-left: 10px;
    }
  }
}
