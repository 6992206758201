@import '../../assets/styles/colors.scss';

.footer {
  background: $baltic-sea;
  height: 260px;
  padding: 5px 3%;
  flex-shrink: 0;

  .logoLink {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-top: 60px;
    gap: 10px;

    & span {
      color: $milk;
      font-size: 15px;
    }

    .logo {
      width: 76px;
      min-height: 36px;
      background-image: url("../../assets/images/fio-logo-white-text.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: left;
    }
  }

  .divider {
    opacity: 0.32;
    border-top: 1px solid $milk;
    margin: 30px 0;
  }
}
