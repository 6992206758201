@import '../../assets/styles/colors.scss';
@import '../../assets/styles/fontMixins.scss';
@import '../../assets/styles/mediaQueriesMixings.scss';

.dropdown {
  margin-bottom: 30px;
  width: 100%;
  min-width: 335px;

  &.noMinWidth {
    min-width: auto;
  }

  &.isShort {
    min-width: 275px;
  }

  &.isSmall {
    min-width: 175px;
  }

  &.isWidthResponsive {
    min-width: 0;
  }

  &.fitContentWidth {
    width: fit-content;
  }

  &.hasAutoWidth {
    min-width: auto;
  }

  &.withoutMarginBottom {
    margin-bottom: 0;
  }

  .icon {
    margin-left: 20px;
    font-size: 20px;
    color: $gray-main;

    &.isWhiteIcon {
      color: $white;
    }
  }
}

.control {
  width: 100%;
  background-color: $light-gray;
  border-radius: 7px;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 15px;
  justify-content: space-between;
  border: none;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  @include sf-pro-display-medium;
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.37);

  &.isWhite {
    background-color: $white;
  }

  &.isViolet {
    background-color: $bright-violet;
  }

  &.isIndigo {
    background-color: $light-indigo;
    .placeholder {
      color: $white;
      white-space: nowrap;
    }

    svg {
      color: $white;
    }
  }

  &.isDark {
    background-color: $black-cow;
    .placeholder {
      color: $white;
      white-space: nowrap;
    }

    svg {
      color: $white;
    }
  }

  &.isSimple {
    box-shadow: none;
  }

  &.isHigh {
    height: 54px;
  }

  &.hasAutoHeight {
    height: auto;
  }

  &.hasError {
    border: 1px solid $warn-red;
  }

  &.hasBigBorderRadius {
    border-radius: 12px;
  }

  &.hasLightBorder {
    border: 1px solid $light;
  }
}

.placeholder {
  color: $gray-main;

  &.isWhitePlaceholder {
    color: $white;
  }

  &.isBlackPlaceholder {
    color: $baltic-sea;
  }
}
.menu {
  border-radius: 0 0 7px 7px;
  border: none;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.53);
  margin-top: -7px;
  padding-top: 7px;
  max-height: 250px;
  animation-name: expand;
  animation-duration: 0.4s;

  &.hasRelativePosition {
    position: relative;
  }
}

.optionItem {
  @include sf-pro-display-medium;
  margin: 0 auto;
  font-size: 13px;
  color: $baltic-sea;
  text-align: left;
  padding: 9px 20px 9px 20px;

  &:before {
    content: ' ';
    position: absolute;
    width: calc(100% - 20px);
    left: 10px;
    margin: -9px auto 0;
    border-bottom: 1px solid $light;
  }

  &:first-child:before {
    border: none;
  }

  &:hover {
    color: $white;
    background-color: $dark-gray;
  }

  &:hover:before {
    border-color: $dark-gray;
  }
}

.optionButton {
  @include sf-pro-display-medium;
  background-color: $dark-gray;
  text-align: left;
  color: $white;
  height: 32px;
  border-radius: 7px;
  margin: 9px 10px 10px 10px;
  display: flex;
  align-items: center;
  font-size: 13px;
  border-bottom-right-radius: 7px !important; //rewrite default dropdown styles
  border-bottom-left-radius: 7px !important; //rewrite default dropdown styles
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.11);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:hover {
    color: $white;
    background-color: $black-medium;
  }
}
