@import '../../assets/styles/fontMixins.scss';

.title {
  @include proxima-nova-bold;
}

.icon {
  font-size: 20px;

  &.iconOnTop {
    align-self: flex-start;
  }
}

.textContainer {
  text-align: left;

  .message {
    word-break: break-word;
  }

  .messageOnNewLine {
    display: block;
    margin-top: 10px;
  }

  .messageOnLeft {
    margin-left: -40px;
  }
}

.boldMessage {
  @include proxima-nova-bold;
}
