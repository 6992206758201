@import '../../assets/styles/fontMixins';
@import '../../assets/styles/colors';
@import '../../assets/styles/forms';

.skip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon {
    color: white;
    width: 77px;
    height: 77px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .subtitle {
    font-size: 15px;
    line-height: 18px;
    width: 92%;
    margin: 15px auto;
    @include proxima-nova-thin
  }

  .button {
    width: 100%;
    height: 54px;
    @include proxima-nova-bold;
    margin-bottom: 40px;
    background-color: $baltic-sea;
    color: $white;
    border-color: $baltic-sea;
    border-radius: 100px;
  }
}

.formBox {
  display: flex;
  flex-direction: row;

  .box {
    flex-shrink: 0;
    position: relative;
    width: 100%;
    left: 0;
    transition: all 0.3s ease;
    height: auto;

    .skipButton {
      cursor: pointer;
      text-align: center;
    }

    .question {
      color: white;
      font-size: 13px;
      line-height: 20px;
      box-shadow: 0 0 8px 0 rgba(0,0,0,0.11);
      background-color: $light-indigo;
      border-radius: 7px;
      padding: 7px 20px 7px 20px;
      margin-bottom: 30px;
      min-height: 54px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.isSelected {
        cursor: default;
        opacity: 0.7;
      }
    }

    &.show {
      left: calc(-100% - 30px);
      &:nth-child(1) {
        height: 0;
      }
      &:nth-child(2) {
        height: auto;
      }
    }

    &:nth-child(1) {
      margin-right: 30px;
      height: auto;
    }
    &:nth-child(2) {
      margin-top: 20px;
      height: 0;
    }
  }
}

.arrow {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  margin: 0;
  color: white;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  transform: translateX(0);
  font-size: 1rem;

  &:hover {
    transform: translateX(-4px);
  }
}

.scrollBar {
  height: 350px;
  margin-bottom: 30px;
}


.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 54px;
  padding: 0px 20px;
  border: 1px solid $light-indigo;
  box-sizing: border-box;
  border-radius: 7px;
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.11);
  background: transparent;
  color: white !important;
  outline: none;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 30px;

  &:hover .icon {
    color: $light-indigo;
  }

  .icon {
    margin-left: 15px;
  }

  &.disabled {
    cursor: default;
    .icon {
      color: $white;
    }
  }
}

.scrollThumbY {
  background: $light-indigo !important;
}

.scrollTrackY {
  background: white !important;
  right: -10px !important;
  width: 5px !important;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .icon {
    margin-bottom: 15px;
    width: 80px;
    height: 80px;
  }

  .title {
    font-size: 1.375rem;
    @include proxima-nova-bold;
  }

  .text, .infoText {
    font-size: 0.875rem;
  }

  .text {
    @include proxima-nova-thin;
  }

  .infoText {
    @include proxima-nova-regular-it;
  }
}
