@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/fontMixins.scss';

.button {
  border: none;
  background: none;
  color: $white;
  margin-bottom: 0;
  height: fit-content;
  @include proxima-nova-semibold;

  &.thin {
    @include proxima-nova-thin;
  }

  &.black {
    color: $gray-main;
  }

  &.indigo {
    color: $light-indigo;
  }

  &.bottomMargin {
    margin-bottom: 20px;
  }

  &.topMargin {
    margin-top: 20px;
  }
}
