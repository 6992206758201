@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mediaQueriesMixings.scss';
@import '../../assets/styles/fontMixins.scss';

.badge {
  width: 100%;
  max-width: 1040px;
  border-radius: 8px;
  color: white;
  display: flex;
  align-items: center;
  font-size: 14px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-out;

  &.hasDefaultFontSize {
    font-size: 16px;
  }

  &.withoutMaxWidth {
    max-width: none;
  }

  & > div {
    margin: 0 auto 0 0;
    @include proxima-nova-thin;
  }

  &.show {
    max-height: fit-content;
    margin: 30px 0 0;
    padding: 15px 20px;
  }

  &.withoutMargin {
    margin: 0;
  }

  &.hidden {
    margin: 0 !important;
  }

  &.warning {
    background-color: $simple-orange;
  }
  &.alert, &.error {
    background-color: $simple-red;
  }
  &.info {
    background-color: $bright-turquoise;
  }
  &.success {
    background-color: $irish-green;
  }
  &.regular {
    background-color: $light-indigo;
  }
  &.simple {
    background-color: $desert-storm;
  }
  &.white {
    background-color: $white;
  }
  &.black {
    background-color: $dynamic-black;
  }
  &.red {
    background-color: $warn-red;
  }
  &.bordered {
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid $gallery;
    color: $dark-jungle-green;
  }
}

@include sm {
  .badge {
    align-items: flex-start;
    &.show {
      padding: 20px;
    }
  }
}

@include lg {
  .badge {
    & > div {
      margin: 0 auto 0 20px;
    }
  }
}
