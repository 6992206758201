@import '../../assets/styles/colors.scss';
@import '../../assets/styles/fontMixins.scss';
@import '../../assets/styles/mediaQueriesMixings.scss';

.textContainer {
  margin-left: 10px !important;
  @include proxima-nova-thin;
}

.title {
  @include proxima-nova-bold;
}

.icon {
  font-size: 20px;
  align-self: flex-start;
}

.arrow {
  font-size: 15px;
  margin: 0 30px;
  transition: 0.2s ease-in-out;
  transform: translateX(0);
  align-self: flex-start;

  &:hover {
    transform: translateX(4px);
  }
}

.closeIcon {
  margin-left: 10px !important;
  font-size: 20px !important;
  transition: 0.3s ease-in-out !important;
  align-self: flex-start;

  &:hover {
    transform: rotate(90deg);
  }
}

.closeIcon,
.arrow {
  cursor: pointer;
}

.badgeContainer {
  max-width: 465px;
  border-radius: 100px;
  margin-top: 25px !important;
  margin-bottom: -5px !important;
  text-align: left;
  max-height: 0;
  overflow: hidden;
  align-items: center;

  &.show {
    max-height: fit-content;
    min-height: 54px;
    padding: 10px 20px 10px 10px !important;
  }

  .contentContainer {
    display: flex;
    margin-left: 10px;
    flex-direction: column;
    gap: 5px;

    .title {
      margin: 0;
      font-size: 1.0625em;
      @include sf-pro-display-bold;
    }
    .message {
      margin: 0;
      font-size: 0.75em;
      @include sf-pro-display-regular;
    }
  }

  .clearIcon {
    transition: 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: rotate(90deg);
    }
  }
}

.marginBottomXs {
  margin-bottom: 30px !important;
}

.marginTopZero {
  margin-top: 0 !important;
}

.marginAuto {
  margin: 30px auto !important;
}

.marginBottomZero {
  margin-bottom: 0 !important;
}

@include sm {
  .icon,
  .closeIcon {
    font-size: 18px;
  }

  .arrow {
    margin-left: 0;
    margin-top: 10px;
    transform: scale(1.2);
    display: block;
  }
}
