@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mediaQueriesMixings.scss';
@import '../../../assets/styles/fontMixins.scss';

.button {
  position: relative;
  font-size: 1em;
  border-radius: 100px;
  padding: 15px;
  margin-bottom: 0;
  border-style: none;
  max-width: 335px;
  width: 100%;
  outline: none;
  box-shadow: none;
  color: $white;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  &:focus {
    box-shadow: none;
  }

  & > div {
    min-width: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.hasNoSidePaddings > div {
    min-width: auto;
  }

  &.topMargin {
    margin-top: 30px;
  }

  &.bottomMargin {
    margin-bottom: 20px;
  }

  &.withoutMargin {
    margin: 0;
  }

  &.hasAutoWidth {
    width: auto;
  }

  &.defaultColor {
    background-color: $light-indigo;
    border-color: $light-indigo;

    &:hover {
      background-color: $iris;
      border-color: $iris;
    }
  }

  &.isWhiteBordered {
    border: 1px solid $white;

    &:hover {
      border: 1px solid $white;
    }
  }

  &.isTransparent {
    background-color: transparent;
  }

  &.hasWhiteHoverBackground {
    &:hover {
      background-color: $white;
    }
  }

  &.hasHoveredBlackTextColor {
    &:hover {
      color: $baltic-sea;
    }
  }

  &.isWhite {
    background-color: $white;
    border-color: $white;
    color: $baltic-sea;

    &:hover {
      background-color: $milk;
      border-color: $milk;
    }
  }

  &.isGreen {
    background-color: $green-cyan;
    border-color: $green-cyan;

    &:hover {
      background-color: $green-teal;
      border-color: $green-teal;
    }
  }

  &.isGreenTeal {
    background-color: $green-teal;
    border-color: $green-teal;

    &:hover {
      background-color: $green-cyan;
      border-color: $green-cyan;
    }
  }

  &.isBlack {
    border: $onyx;
    background-color: $onyx;

    &:hover {
      border: $content-background;
      background-color: $content-background;
    }
  }

  &.isLightBlack {
    border: $baltic-sea;
    background-color: $baltic-sea;

    &:hover {
      border: $content-background;
      background-color: $content-background;
    }
  }

  &.isCobalt {
    border: $cobalt;
    background-color: $cobalt;

    &:hover {
      border: $royal-blue;
      background-color: $royal-blue;
    }
  }

  &.isRed {
    border: $warn-red;
    background-color: $warn-red;

    &:hover {
      border: $simple-red;
      background-color: $simple-red;
    }
  }

  &.isWhiteViolet {
    background-color: $white;
    color: $light-indigo;
    border: 1px solid;

    &:hover {
      background-color: $light-indigo;
      color: $white;
    }
  }

  &.isGrey {
    border: $silver-chalice;
    background-color: $silver-chalice;
    color: $black;

    &:hover {
      border: $iron;
      background-color: $iron;
    }
  }

  &.isBlue {
    border: $violet;
    background-color: $violet;
    color: $white;

    &:hover {
      border: $clear-blue;
      background-color: $clear-blue;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.loading {
    cursor: progress;
  }

  &.hasLowHeight {
    height: 45px;
    padding: 0;
  }

  &.hasAutoHeight {
    height: auto;
    padding: 0;
  }

  &.hasBoldText {
    @include proxima-nova-bold;
  }

  &.hasSmallText {
    font-size: 0.875em;
    line-height: 1;
  }

  &.hasSmallPaddings {
    height: auto;
    padding: 10px 5px;
  }

  .loader {
    & > span {
      color: inherit !important;
      margin-top: -4px;
      margin-bottom: -4px;
      width: 16px !important;
      height: 16px !important;
    }
  }
}

@include sm {
  .button {
    max-width: 100%;
  }
}
