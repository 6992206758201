@import '../../assets/styles/colors.scss';
@import '../../assets/styles/fontMixins.scss';
@import '../../assets/styles/mediaQueriesMixings.scss';

.container {
  color: $baltic-sea;
  text-align: center;
  padding: 100px 32px;
  width: 100%;
  flex: 1;
  overflow: hidden;

  &.isReverseColors {
    background: $light-indigo;
    .title,
    .subtitle,
    .actionInfo {
      color: $white;
    }
  }

  &.isDarkWhite {
    background: $dark-baltic-sea;
    .title,
    .subtitle,
    .actionInfo {
      color: $white;
    }
  }

  .logoImg {
    max-width: 380px;
    max-height: 115px;
    width: 100%;
  }

  .title {
    margin: 0;
    font-size: 2.625rem;
    line-height: 3.75rem;
    color: $baltic-sea;
    @include comfortaa-bold;
  }

  .subtitle {
    margin: 15px auto;
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: $baltic-sea;
    max-width: 1000px;
    @include proxima-nova-semibold;
  }

  .actionInfo {
    margin: 15px 0;
    font-size: 1rem;
    color: $black;
    @include proxima-nova-regular;
  }

  .bottom {
    height: 110px;
  }
}

@include sm {
  .container {
    padding: 70px 32px;

    .title {
      text-align: center;
      font-size: 2rem;
      line-height: 3.125rem;
    }

    .subtitle {
      text-align: center;
      font-size: 1.125rem;
      line-height: 1.75rem;
    }

    .newLine {
      display: block;
    }

    .bottom {
      height: 30px;
    }
  }
}

.refContainer {
  min-height: 75vh;
}

.hasMinHeight {
  min-height: 75vh;
}

.step {
  margin-top: 30px;
}
