@import '../../assets/styles/colors';
@import '../../assets/styles/mediaQueriesMixings';

.header {
  display: flex;
  background: $white;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.2);
  padding: 5px 25px;
  min-height: 80px;

  &.noBoxShadow {
    box-shadow: none;
  }

  &.isOpen {
    position: sticky;
    top: 0;
    z-index: 1002;
  }

  .logoContainer {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 36px;

      @include sm {
        width: 76px;
        height: auto;
      }
    }
  }

  .logo {
    cursor: pointer;
    width: 76px;
    height: 100%;
    background-image: url("../../assets/images/fio-logo.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left;
  }
}

@include lg {
  .header {
    padding: 5px 3.75rem;
  }
}
