@import '../../../assets/styles/colors.scss';

.pin {
  width: 219px;
  margin: 15px auto 0;
  position: relative;

  &.withoutMargin {
    margin: 0 auto;
  }

  .pinInput {
    opacity: 0;
    width: 100%;
    color: $baltic-sea;
  }

  .dotsContainer{
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .dotEmpty {
      height: 25px;
      width: 25px;
      border: 1px solid $light-indigo;
      border-radius: 50%;
      background: none;
      color: transparent;

      &.dotFull {
        background-color: $light-indigo;
      }
      &.dotError {
        background-color: $warn-red;
        border-color: $warn-red;
      }
    }
  }
}

.pinError {
  font-size: 12px;
  position: relative;
  height: 40px;
  background-color: $warn-red;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.11);
  margin-bottom: 17px;

  .icon {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 15px;
  }
}
