@import '../../assets/styles/colors.scss';
@import '../../assets/styles/fontMixins.scss';
@import '../../assets/styles/mediaQueriesMixings.scss';

.regInputWrapper {
  width: 100%;
  color: white;
  margin-bottom: 30px;
  overflow: hidden;

  &.withoutBottomMargin {
    margin-bottom: 0;
  }

  .dark {
    background-color: $baltic-sea !important;
    color: $white !important;
  }

  .connectedWalletIcon {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }

  .fullCircle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $light-indigo;
    margin-right: 10px;
  }

  .badge {
    margin: 0 0 20px 0;
  }

  .errorMessage {
    max-height: 0;
    font-size: 12px;
    line-height: 15px;
    margin-left: 20px;
    transition: max-height 0.3s ease-out;

    .errorIcon {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      max-height: inherit;
    }

    &.error {
      max-height: 30px;
      margin-bottom: 8px;
      margin-top: 13px;
      display: flex;
      align-items: center;
    }

    &.visible {
      visibility: visible;
    }
    &.hidden {
      visibility: hidden;
    }

    &.white {
      color: $white;
    }

    &.warn {
      color: $warn-red;
    }
  }
}

.indigo {
  color: $light-indigo !important;
}

.label {
  font-size: 0.875em;
  margin-bottom: 1em;
  @include sf-pro-display-medium;
  color: $white;
  width: 100%;

  &.blackLight,
  &.blackGray,
  &.blackWhite,
  &.blackWhiteBorder,
  &.indigoWhite {
    color: $dark-jungle-green;
  }

  &.hasItalic {
    @include proxima-nova-regular-it;
  }

  b {
    @include sf-pro-display-bold;
  }
}

.labelSuffix {
  font-size: 0.875em;
  @include sf-pro-display-thin;
  color: $gray-main;

  &.blackLight,
  &.blackGray,
  &.blackWhiteBorder,
  &.blackWhite {
    color: $baltic-sea;
  }

  &.withBottomMargin {
    margin-bottom: 15px;
  }
}

.cursorPointer {
  cursor: pointer;
}

.additionalSubInfo {
  font-size: 0.875em;
  margin: 10px 20px;
  color: $baltic-sea;

  span {
    color: $baltic-sea;
  }
}

.infoBadge {
  margin: 0 0 20px 0;

  a:hover {
    color: $iron;
  }
}

.inputGroup {
  position: relative;

  .amountInput {
    max-width: 45%;
    @media only screen and (max-width: 575px) {
      max-width: 100%;
    }
  }

  .maxButtonContainer {
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
    width: 60px !important;
    height: 35px !important;

    button {
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0;
      font-size: 14px;
      border-radius: 100px;

      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }
  }

  .selectModalInput {
    pointer-events: none;
  }

  .exchangeIconItem {
    width: 16px;
    margin-right: -4px;
    stroke: $manatee;
    stroke-width: 1;
  }

  .exchangeTextItem {
    font-size: 14px;
    padding: 0 2px;
  }

  .exchangeValue {
    display: flex;
    align-items: center;
    color: $manatee;

    @media only screen and (min-width: 576px) {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }

    @media only screen and (max-width: 575px) {
      margin: 20px 0 0 0;
      background-color: $white;
      width: 100%;
      padding: 16px;
      box-sizing: border-box;
      border-radius: 7px;
    }
  }

  .switchIcon {
    margin: 0 4px;
    height: 18px;
    color: $baltic-sea;
    cursor: pointer;
    @media only screen and (max-width: 575px) {
      margin-left: auto;
    }
  }

  .inputIcon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    width: 20px !important;
    height: 20px;

    &.bw,
    &.blackGray,
    &.blackWhiteBorder,
    &.blackWhite {
      color: $dark-gray;
    }

    &.iw {
      color: $light-indigo;
    }

    &.disabled {
      cursor: default;
    }
  }

  .iconPosition {
    top: 20px;
  }

  .doubleIcon {
    position: absolute;
    right: 60px;
  }

  .inputSpinnerIcon {
    cursor: default;

    &.indigoWhite {
      color: $light-indigo;
    }
  }

  .prefixLabel {
    background-color: transparent;
    display: inline-block;
    @include proxima-nova-bold;
    padding-right: 20px;
  }

  .regInput {
    width: 100%;
    height: 54px;
    padding: 0px 20px;
    border: 1px solid $light-indigo;
    box-sizing: border-box;
    border-radius: 7px;
    background: transparent;
    color: white;
    outline: none;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    input, textarea {
      outline: none;
      background: transparent;
      box-sizing: border-box;
      border: none;
      height: 54px;
      font-size: 14px;
      color: white;
      width: 100%;
      flex: 1;

      &::placeholder {
        color: inherit;
        @include proxima-nova-regular-it;
      }

      &[data-clear='true'] {
        padding-right: 35px;

        &.suffixSpace {
          padding-right: 135px;
        }
      }
    }

    textarea {
      height: 500px;
    }

    &.prefixSpace {
      padding-left: 45px;
    }

    &.hasPasteButton {
      padding-right: 45px;
    }

    &.suffixSpace {
      padding-right: 80px;
    }

    &.iw {
      background-color: $white;
      border-color: $light-indigo;
      color: $light-indigo !important;
      height: 40px;

      input, textarea {
        color: $light-indigo !important;

        &::placeholder {
          @include proxima-nova-regular-it;
        }
      }
    }

    &.bw {
      background-color: $white;
      border-color: transparent;
      color: $gray-main !important;
      height: 40px;

      input, textarea {
        color: $gray-main !important;

        &::placeholder {
          @include proxima-nova-regular-it;
        }
      }
    }

    &.bg {
      background-color: $aqua-haze;
      border-color: $aqua-haze;
      color: $gray-main !important;

      input, textarea {
        color: $gray-main !important;
    
        &::placeholder {
          @include proxima-nova-regular-it;
        }
      }
    }

    &.error {
      border-color: $warn-red;
    }

    &.lowHeight {
      height: 40px;
    }

    &.middleHeight {
      height: 45px;
    }

    &.hasRegularHeight {
      height: 54px;
    }

    &.blackWhiteBorder {
      color: $baltic-sea;
    
      input,
      textarea {
        color: $baltic-sea;
    
        &::placeholder {
          color: $dove-gray;
        }
      }
    
      &.error {
        border-color: $warn-red;
      }
    }

    &.blackLight,
    &.blackGray,
    &.blackWhite {
      border-color: transparent;
      color: $baltic-sea;

      input, textarea {
        color: $baltic-sea;

        &::placeholder {
          color: $dove-gray;
        }
      }

      &.error {
        border-color: $warn-red;
      }
    }

    &.blackViolet {
      background-color: $baltic-sea;
      border-color: $iris;
      color: $white;

      &.error {
        border-color: $warn-red;
      }
    }

    &.blackLight {
      background-color: $light-gray;
    }

    &.blackGray {
      background-color: $aqua-haze;
    }

    &.blackWhite, &.blackWhiteBorder {
      background-color: $white;
    }

    &.disabledGray {
      background-color: $mine-shaft;
      opacity: 0.5;
    }

    &.textarea {
      height: 500px;
    }

    &.doubleIconInput {
      padding-right: 90px;

      input[data-clear='true'] {
        padding-right: 0;
      }
    }

    &.connectWallet {
      padding-right: 120px;

      &.hasPasteButton {
        padding-right: 150px;
      }

      input[data-clear='true'] {
        padding-right: 0;
      }
    }

    &.isWalletConnected {
      input[data-clear='true'] {
        padding-right: 0;
      }
    }

    &.hasRoundRadius {
      border-radius: 100px;
    }
  }
}

.prefix {
  background-color: $dark-gray;
  color: white;
  position: absolute;
  padding: 0px 10px;
  height: 40px;
  display: flex;
  align-items: center;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  min-width: 33px;

  &.highZIndex {
    z-index: 4;
  }

  &.indigoWhite {
    background-color: $light-indigo;
  }

  &.error {
    height: 38px;
    top: 1px;
    left: 1px;
  }

  &.hasRoundRadius {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
}

.suffix {
  position: absolute;
  color: $baltic-sea;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);

  &.indigoWhite {
    color: $light-indigo;
  }

  &.hasIconsLeft {
    right: 50px;
  }

  &.disabledGray {
    opacity: 0.5;
  }
}

.pin {
  width: 219px;
  margin: 56px auto 70px;
  position: relative;

  .pinInput {
    opacity: 0;
    width: 100%;
    color: $baltic-sea;
  }

  .dotsContainer {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .dotEmpty {
      height: 25px;
      width: 25px;
      border: 1px solid $light-indigo;
      border-radius: 50%;
      background: none;
      color: transparent;

      &.dotFull {
        background-color: $light-indigo;
      }
      &.dotError {
        background-color: $warn-red;
        border-color: $warn-red;
      }
    }
  }
}

.pinError {
  font-size: 12px;
  position: relative;
  height: 40px;
  background-color: $warn-red;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
  margin-bottom: 17px;

  .icon {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 15px;
  }
}

.fileContainer {
  background-color: $light-gray;
  border-radius: 7px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
  color: $baltic-sea;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;

  .fileInput {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .icon {
    font-size: 40px;
    margin-top: 30px;
  }
  .text {
    font-size: 0.875em;
    margin-top: 15px;
    margin-bottom: 22px;
  }
  .image {
    width: 100%;
  }
}

.checkboxContainer input[type='checkbox'],
.checkboxContainer input[type='checkbox'] ~ .checked {
  display: none;
}

.checkboxContainer input[type='checkbox']:checked ~ .checked {
  display: inline-block;
}

.checkboxContainer input[type='checkbox']:checked ~ .unchecked {
  display: none;
}

.checkboxContainer {
  cursor: pointer;
  margin-bottom: 0;
  display: flex;
  align-items: center;

  .unchecked,
  .checked {
    font-size: 22.87px;
    color: $baltic-sea;

    &.blackWhite {
      color: $white;
    }

    &.smallText {
      font-size: 16px;
    }
  }

  .label {
    margin: 2px 0 0 10px;
    font-size: 0.875em;
    color: $baltic-sea;
    @include proxima-nova-semibold;

    &.blackWhite {
      color: $white;
    }

    &.smallText {
      font-size: 11px;
    }

    &.hasThinText {
      @include proxima-nova-thin;
    }

    b {
      @include sf-pro-display-regular;
    }
  }
}

.optionsContainer {
  padding: 0 10px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 1.75em;
    font-weight: 500;
  }
  .subtitle {
    color: $dark-gray;
    font-size: 1em;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .notFound {
    text-align: center;
    width: 100%;
    padding: 15px;
    color: $dove-gray;
  }

  .optionsList {
    max-height: 225px;
    overflow-y: auto;

    .option {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      cursor: pointer;
      background-color: $white;
      padding: 15px;
      margin-bottom: 15px;
      border-radius: 7px;

      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: $bright-violet;
        margin-right: 20px;
        color: $white;
      }
    }
  }
}

.connectWallet {
  .connectWalletButton {
    width: 90px !important;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.connectWalletModal {
  padding-bottom: 20px;

  .connectWalletProviderTypeButton {
    padding: 8px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $baltic-sea;
    border-radius: 12px;
    color: $white;
    width: 100%;

    .providerIcon {
      margin-left: 10px;
      width: 30px;
      height: 30px;
    }
  }
}
