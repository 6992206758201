@import '../../assets/styles/fontMixins';
@import '../../assets/styles/colors';
@import '../../assets/styles/forms';

.formBox {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;

  .formFields {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    &.hideForm {
      display: none;
    }
  }

  .captchaContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    position: relative;
    width: 100%;

    .loader {
      position: absolute;
      top: 120px;
    }

    .frame {
      margin: 0;
      padding: 0;
      height: 480px;
      width: 100%;
      border-radius: 20px;
      overflow: hidden;
      visibility: hidden;

      &.show {
        visibility: visible;
      }
  
      iframe {
        width: 100%;
        height: 480px;
        border: none;
      }
    }
  }

  .forgotPasswordLink {
    margin-top: -10px;
    margin-bottom: 15px;
    font-size: 0.75em;
    @include proxima-nova-semibold;
  }

  .forgotPass {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icon {
      color: $light-indigo;
      width: 77px;
      height: 77px;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    .subtitle {
      font-size: 15px;
      line-height: 18px;
      width: 92%;
      margin: 15px auto;
      @include proxima-nova-thin
    }

    .button {
      width: 98%; //should be 100% but transition bug articfact
      height: 54px;
      font-weight: bold !important;
      margin-bottom: 40px;
      background-color: $light-indigo;
      border: 1px solid $light-indigo;
      border-radius: 100px;

      &:hover {
        background-color: $iris;
        border-color: $iris;
      }
    }
  }

  .box {
    flex-shrink: 0;
    position: relative;
    width: 100%;
    left: 0;
    transition: all 0.4s ease;

    .text {
      font-size: 0.875em;
      text-align: center;
      padding: 0 20px;
      margin-bottom: 0;
    }

    .linesContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      margin: 17px 0;

      hr {
        width: 100%;
        box-sizing: content-box;
        border: 1px dashed $lucky-gray;
        border-bottom: none;
      }

      .linesText {
        margin-bottom: 0;
        margin: 0 15px;
      }
    }

    .createAccountContainer {
      font-size: 0.75em;
      margin-bottom: 20px;

      .createAccountLink {
        @include proxima-nova-semibold;
      }
    }

    &.show {
      left: -100%;

      &:first-child {
        height: 0
      }
    }
  }

  .exitPin {
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding-bottom: 20px;
    @include proxima-nova-semibold;

    &:hover {
      color: $mercury;
    }

    .exitPinText {
      padding-left: 15px;
    }
  }
}

.errorBadge {
  position: absolute;
  top: -160px;
  width: calc(100% + 40px);
}

.errorMessage {
  font-size: 13px !important;
  letter-spacing: normal !important;
}
