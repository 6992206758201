@import '../../assets/styles/colors.scss';

.closeButton {
  position: relative;
  border: none;
  background: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:before, &:after {
    position: absolute;
    content: '';
    height: 2px;
    border-radius: 2px;
    background-color: $black;
    width: 20px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:hover {
    transform: rotate(-90deg);
  }
  &.white:before, &.white:after {
    background-color: $white;
  }
}
