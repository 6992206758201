@import '../../assets/styles/colors.scss';
@import '../../assets//styles/fontMixins.scss';

.container {
  &.hasBigTopMargin {
    margin-top: 60px;
  }
}

.baseColored {
  color: white;
  margin-bottom: 18px;
  @include proxima-nova-bold
}

.baseColored.colored {
  color: $light-indigo;
}

.colored[data-highlightword] {
  position: relative;
}

.colored[data-highlightword]::before {
  content: attr(data-highlightword);
  color: white;
  position: absolute;
  top: 0;
  left: 0;
}

.header {
  font-size: 14px;
  margin-bottom: 30px;
}

.subtitle {
  @include proxima-nova-thin
}
