@mixin inter-bold {
  font-family: 'Inter Bold';
}

@mixin inter-semibold {
  font-family: 'Inter Semibold';
}

@mixin inter-italic {
  font-family: 'Inter Italic';
}

@mixin inter-regular {
  font-family: 'Inter Regular';
}

@mixin inter-medium {
  font-family: 'Inter Medium';
}

@mixin inter-thin {
  font-family: 'Inter Thin';
}

@mixin inter-thin-it {
  font-family: 'Inter Thin Italic';
}

@mixin proxima-nova-regular {
  font-family: 'Proxima Nova Regular';
}

@mixin proxima-nova-bold {
  font-family: 'Proxima Nova Bold';
}

@mixin proxima-nova-semibold {
  font-family: 'Proxima Nova Semibold';
}

@mixin proxima-nova-medium {
  font-family: 'Proxima Nova Medium';
}

@mixin proxima-nova-thin {
  font-family: 'Proxima Nova Thin';
}

@mixin proxima-nova-regular-it {
  font-family: 'Proxima Nova Regular Italic';
}

@mixin proxima-nova-thin-it {
  font-family: 'Proxima Nova Thin Italic';
}

@mixin sf-pro-display-regular {
  font-family: 'SF Pro Display Regular';
  letter-spacing: 0.05em;
}

@mixin sf-pro-display-thin {
  font-family: 'SF Pro Display Thin';
  letter-spacing: 0.05em;
}

@mixin sf-pro-display-bold {
  font-family: 'SF Pro Display Bold';
  letter-spacing: 0.05em;
}

@mixin sf-pro-display-medium {
  font-family: 'SF Pro Display Medium';
  letter-spacing: 0.05em;
}

@mixin comfortaa-regular {
  font-family: 'Comfortaa Regular', cursive;
}

@mixin comfortaa-bold {
  font-family: 'Comfortaa Bold', cursive;
}
