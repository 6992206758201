$white: #ffffff;
$black: #000000;
$curious-blue: #20a5ce;
$picton-blue: #46cce1;
$mercury: #e8e8e8;
$elephant: #0f3249;
$dove-gray: #666666;
$alabaster: #f7f7f7;
$cerulean: #00a7cf;
$gallery: #eaeaea;

$foundation-white: #EFEBFF;
$leadbelcher: #CACACA;
$desert-storm: #f8f7f7;
$aria: #E3E3E3;
$dark-jungle-green: #202020;
$black-medium: #040a1e;
$tuna: #333745;
$midnight: #042237;
$oil: #1E1E1E;
$dark-baltic-sea: #252525;
$dark-blue-grey: #283d4f;
$cynical-black: #171717;
$dark-tone-ink: #121212;
$black-oak: #4f4f4f;
$bianca: #fafbfb;
$beige: #faf5f5;
$milk: #eeeeee;
$light: #e2e2e2;
$hint: #f5f2f2;
$aqua-haze: #f3f3f3;
$white-smoke: #f5f5f5;
$blue: #3eb3e3;
$bright-turquoise: #00c1ff;
$cyan: #084169;
$cobalt: #293c92;
$violet: #4588ff;
$fun-blue: #1a5d95;
$clear-blue: #2070ff;
$royal-blue: #2d63d5;
$cyan-blue: #0f6fb8;
$sky-blue: #07aeff;
$dodger-blue: #4786ff;
$megaman: #31B9FF;
$jordy-blue: #97aaff;
$fog: #dfd6ff;
$pile-violet: #c3acff;
$medium-purple: #937ce0;
$bright-violet: #9f3ee3;
$light-indigo: #765cd6;
$purple-pink: #df3cee;
$bright-pink: #F502B1;
$purple-magenta: #B65CD6;
$light-lavender: #e9c0e9;
$iris: #6b53c1;
$crocus-purple: #8b73e2;
$perrywinkle: #A08AEE;
$carousel-pink: #fbebfb;
$dark-slate-blue: #4b398d;
$purple-iris: #502274;
$honey-flower: #4c186e;
$badge-background: #062745;
$warn-red: #d40000;
$simple-red: #e33e3e;
$crimson: #ff0787;
$red: #eb0000;
$blood-burst: #FF4B4B;
$simple-orange: #ff7500;
$bright-orange: #ff5e00;
$blaze-orange: #ff6d07;
$red-orange-juice: #FF5647;
$gadabout: #FFC6AD;
$coral-bisque: #F5C4B0;
$web-orange: #f2a900;
$dull-orange: #E17E36;
$saffron-gold: #EF9103;
$saffron: #f3ba2f;
$buried-gold: #DEDFE1;
$supernova: #ffce00;
$twine: #c99d66;
$rio-grande: #cfc606;
$golden-foil: #CCD000;
$green: #74f87f;
$tradewind: #50af95;
$green-main: #00ab59;
$irish-green: #03b12d;
$green-teal: #0fb871;
$green-cyan: #00c366;
$green-shell:#5CD551;
$benzol-green: #00D26D;
$green-tea: #c2f5bb;
$light-seafoam: #9bffca;
$humming-bird: #d3faea;
$fountain-blue: #55bdb8;
$blue-green: #059ba6;
$dark-gray: #282828;
$dark: #202934;
$dark-gunmetal: #1C242E;
$shark: #1c1c1e;
$dynamic-black: #1D1D1D;
$black-suede: #434343;
$gray-main: #484848;
$cod-gray: #1c1c1c;
$black-cow: #474747;
$bauhaus: #3F3F3F;
$briquette-grey: #515151;
$onyx: #0f0f0f;
$light-gray: #ececec;
$mine-shaft: #353535;
$baltic-sea: #2b2b2e;
$black-olive: #3C3D3F;
$gainsboro: #dbdbdb;
$manatee: #90949c;
$silver-chalice: #afafaf;
$silver: #c9c9c9;
$gallery: #ededed;
$iron: #d7d7d7;
$vista-white: #fef7f7;
$ghost-white: #f7f7ff;
$showflake: #f0f0f0;
$mountain-mist: #979797;
$tundora: #444444;
$lucky-gray: #767676;
$twitter-blue: #00acee;
$aqua-blue: #0276e4;
$off-white: #e1e8ed;
$main-background: $shark;
$content-background: $shark;
$gradient-background: linear-gradient(
  90deg,
  $humming-bird 0%,
  $fog 53%,
  $carousel-pink 100%
);
$black-gradient: linear-gradient(
  270deg,
  rgba(43, 43, 46, 1) 0%,
  rgba(39, 39, 39, 1) 100%
);
$indigo-gradient: linear-gradient(
  270deg,
  $light-indigo 0%,
  #22a2ff 100%
);
$violet-gradient: linear-gradient(
  270deg,
  rgba(149, 121, 221, 1) 0%,
  rgba(133, 106, 217, 1) 34%,
  rgba(118, 92, 214, 1) 100%
);
$beige-gradient: linear-gradient(
  270deg,
  rgba(250, 245, 245, 1) 0%,
  rgba(237, 236, 236, 1) 100%
);
