@import '../../../../assets/styles/colors';

.menuIcon {
  width: 24px;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;

  & span {
    display: block;
    position: absolute;
    height: 1px;
    width: 50%;
    background: $baltic-sea;
    opacity: 1;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  & span:nth-child(even) {
    left: 50%;
    border-radius: 0 0.5px 0.5px 0;
  }

  & span:nth-child(odd) {
    left: 0px;
    border-radius: 0.5px 0 0 0.5px;
  }

  & span:nth-child(1),
  & span:nth-child(2) {
    top: 0px;
  }

  & span:nth-child(3),
  & span:nth-child(4) {
    top: 9px;
  }

  & span:nth-child(5),
  & span:nth-child(6) {
    top: 19px;
  }

  &.isOpen span:nth-child(1),
  &.isOpen span:nth-child(6) {
    transform: rotate(45deg);
  }

  &.isOpen span:nth-child(2),
  &.isOpen span:nth-child(5) {
    transform: rotate(-45deg);
  }

  &.isOpen span:nth-child(1) {
    left: 2px;
    top: 4px;
  }

  &.isOpen span:nth-child(2) {
    left: calc(50% - 2px);
    top: 4px;
  }

  &.isOpen span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }

  &.isOpen span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }

  &.isOpen span:nth-child(5) {
    left: 2px;
    top: 12px;
  }

  &.isOpen span:nth-child(6) {
    left: calc(50% - 2px);
    top: 12px;
  }
}
