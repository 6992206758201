@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mediaQueriesMixings.scss';

.modal {
  width: 100%;
  min-width: 375px;
  background-color: $baltic-sea;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 20px 20px;
  overflow: hidden;
  color: $white;

  &.danger {
    background-color: $warn-red;
  }

  &.simple {
    background-color: $milk;
    color: $baltic-sea;
  }

  &.success {
    background-color: $green-teal;
  }

  &.info {
    background-color: $cyan-blue;
  }
  &.blue {
    background-color: $bright-turquoise;
  }
  &.indigo {
    background-color: $light-indigo;
  }
  &.isWhite {
    background-color: $white;
    color: $baltic-sea;
  }

  &.enableOverflow {
    overflow: visible;
  }
}

.dialog {
  margin-top: 160px;
  width: 375px;

  &.fullWidth {
    width: 90%;
    max-width: 1200px;
  }

  &.isWide {
    max-width: 650px;
    width: auto;
  }

  &.isMiddleWidth {
    max-width: 560px;
    width: auto;
  }

  &.defaultCloseColor {
    span[aria-hidden=true] {
      color: $baltic-sea !important;
    }
  }

  .smallText {
    font-size: 14px;
  }
}

.backdrop {
  opacity: 0.8 !important;
}

.backdropSecondModal {
  z-index: 1050;
  opacity: 0.3 !important;
}

@include sm {
  .modal {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    min-width: 100%;
    padding-bottom: 80px;
    border: none;

    &.withoutPaggingBottom {
      padding-bottom: 0;
    }
  }
  .dialog {
    max-height: 90vh;
    margin: 0;
    width: 100%;
    top: 100%;
    transform: translateY(-100%) !important;
    animation: slide-up 0.4s ease-in-out;
  }
}

@keyframes slide-up {
  from {
    top: 200%;
    transform: translateY(0) !important;
  }

  to {
    top: 100%;
    transform: translateY(-100%) !important;
  }
}
