@import '../../assets/styles/colors';

.container {
  width: 100%;

  .validationErrorContainer {
    background: $main-background;
    color: white;
    text-align: center;
    padding: 9% 25px 0;
    min-height: 75vh;
  }
}
